import { FC, useEffect, useMemo, useState } from "react";

import {
  Box,
  ChevronLeftIcon,
  ChevronRightIcon,
  Column,
  IconButton,
  Badge,
  Row,
  SearchInput,
  Text,
  Tooltip,
} from "@hightouchio/ui";
import orderBy from "lodash/orderBy";
import { isPresent } from "ts-extras";
import { format } from "numerable";

import searchPlaceholder from "src/assets/placeholders/search.svg";
import {
  GraphSeries,
  GroupColumn,
} from "src/components/analytics/cross-audience-graph/types";
import { getSeriesDescription } from "src/components/analytics/cross-audience-graph/utils";
import {
  getModelIdFromColumn,
  getPropertyNameFromColumn,
} from "src/components/explore/visual/utils";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import { ColumnReference } from "src/types/visual";
import { Pagination, Table, TableColumn, useTableConfig } from "src/ui/table";
import { accurateCommaNumber } from "src/utils/numbers";

import { AnalyticsBar } from "./analytics-table/analytics-bar";
import { useAnalyticsContext } from "./state";
import { GroupByColumn, ParentModel } from "./types";
import {
  getMaxBreakdownValue,
  getNumberOfUniqueValues,
  isGroupByColumnRelatedToParent,
  shouldUsePercentFormat,
} from "./utils";

export type BreakdownTableProps = {
  data: GraphSeries[];
  groupByColumns?: GroupByColumn[];
  isLoading?: boolean;
  enableSearch?: boolean;
  // At minimum, the metric column is shown unless specified to hide it
  hideMetricColumn?: boolean;
  // The table's value column will not have a header unless a valueColumnName is provided
  valueColumnName?: string;
  rowsLimit?: number;
};

const metricNameKey = "metricName";
const audienceNameKey = "audienceName";
const splitNameKey = "splitName";

const getTdStyles = ({
  isLastColumn,
  isFirstOfGroup,
  isLastOfGroup,
}: {
  isLastColumn: boolean;
  isFirstOfGroup: boolean;
  isLastOfGroup: boolean;
}) => {
  if (isLastColumn) {
    return { display: "flex", alignItems: "center", px: 4 };
  }

  return {
    bg: "base.background",
    px: 4,
    mx: 1,
    ":first-of-type": {
      ml: 0,
    },
    display: "flex",
    alignItems: "center",
    minHeight: "36px",

    span: {
      visibility: "hidden",
    },
    ...(isFirstOfGroup && {
      borderTopRadius: "sm",
      mt: 1,
      span: { visibility: "visible" },
    }),
    ...(isLastOfGroup && { borderBottomRadius: "sm" }),
  };
};

const move = (
  array: string[],
  columnName: string,
  direction: "left" | "right",
) => {
  const index = array.findIndex((name) => name === columnName);
  const newArray = [...array];
  const item = newArray.splice(index, 1)[0];

  if (item) {
    if (direction === "left") {
      newArray.splice(index - 1, 0, item);
    } else if (direction === "right") {
      newArray.splice(index + 1, 0, item);
    }
    return newArray;
  }

  return array;
};

// Include model id to avoid name collisions between parent and event groupBy columns
const getGroupingKey = (
  column: ColumnReference,
  parent: ParentModel | null,
) => {
  const columnModelId = getModelIdFromColumn(column);
  const columnName = getPropertyNameFromColumn(column) ?? "--";

  return isGroupByColumnRelatedToParent(parent, column as GroupByColumn)
    ? `${columnModelId}-${columnName}`
    : columnName;
};

const getMaxDecimalPlaces = (data: GraphSeries[]) => {
  let maxDecimalPlaces = 0;

  data.forEach(({ data }) => {
    const value = data?.[0]?.metricValue ?? 0;
    if (!Number.isFinite(value) || Number.isInteger(value)) {
      return;
    }

    const valueString = value.toString();
    const decimalIndex = valueString.indexOf(".");
    maxDecimalPlaces = Math.max(
      decimalIndex === -1 ? 0 : valueString.length - decimalIndex - 1,
      maxDecimalPlaces,
    );
  });

  return maxDecimalPlaces;
};

const getColumnKeys = (
  groupByColumnPaths: string[],
  hideMetricColumn: boolean,
  hasSplits: boolean,
  numberOfUniqueAudiences: number,
) => {
  const keys: string[] = [];

  if (!hideMetricColumn) {
    keys.push(metricNameKey);
  }

  if (numberOfUniqueAudiences > 1) {
    keys.push(audienceNameKey);
  }

  if (hasSplits) {
    keys.push(splitNameKey);
  }

  if (groupByColumnPaths.length > 0) {
    keys.push(...groupByColumnPaths);
  }

  return keys;
};

export const BreakdownTable: FC<BreakdownTableProps> = ({
  data,
  groupByColumns = [],
  isLoading = false,
  enableSearch = true,
  hideMetricColumn = false,
  valueColumnName,
  rowsLimit,
}) => {
  const hasSplits = data.some(({ splitName }) => Boolean(splitName));
  // Only show audience column if > 1 audience selected
  const numberOfUniqueAudiences = getNumberOfUniqueValues(data, "audienceName");

  const { parent } = useAnalyticsContext();
  const { page, limit, offset, setPage } = useTableConfig({
    limit: rowsLimit,
  });
  const [search, setSearch] = useState("");

  const groupByColumnPaths = useMemo(
    () =>
      (data?.[0]?.grouping || groupByColumns)
        .map((_, groupingIndex) => `grouping.${groupingIndex}.value`)
        .filter(isPresent),
    [data, groupByColumns],
  );

  const [columnKeys, setColumnKeys] = useState(
    getColumnKeys(
      groupByColumnPaths,
      hideMetricColumn,
      hasSplits,
      numberOfUniqueAudiences,
    ),
  );

  useEffect(() => {
    setColumnKeys(
      getColumnKeys(
        groupByColumnPaths,
        hideMetricColumn,
        hasSplits,
        numberOfUniqueAudiences,
      ),
    );
  }, [
    groupByColumnPaths,
    numberOfUniqueAudiences,
    metricNameKey,
    splitNameKey,
  ]);

  const filteredData = useMemo(() => {
    const result = data.filter(
      ({ metricName, splitName, description, grouping, data }) => {
        const groupMetadata =
          grouping?.flatMap(({ column, value }) => [
            getPropertyNameFromColumn(column)?.toLowerCase() ?? "",
            value?.toLowerCase() ?? "--",
          ]) ?? [];
        const lowerCasedSearch = search.toLowerCase().trim();

        const formattedMetricValue =
          data?.[0]?.metricValue !== undefined
            ? accurateCommaNumber(data?.[0]?.metricValue)
            : undefined;

        return (
          metricName.toLowerCase().trim().includes(lowerCasedSearch) ||
          description.toLowerCase().trim().includes(lowerCasedSearch) ||
          splitName?.toLowerCase().includes(lowerCasedSearch) ||
          groupMetadata.filter((value) => value.includes(lowerCasedSearch))
            .length > 0 ||
          (formattedMetricValue &&
            (formattedMetricValue.includes(lowerCasedSearch) ||
              formattedMetricValue.replace(",", "").includes(lowerCasedSearch)))
        );
      },
    );

    const filterKeys = (columnKeys as string[])
      .slice(0, columnKeys.length - 1)
      .concat(["data.0.metricValue"]);
    const directionArray = Array(filterKeys.length - 1)
      .fill("asc")
      // Last column is a number (value) so it'll be sorted in descending order
      .concat("desc");

    return orderBy(result, filterKeys, directionArray);
  }, [columnKeys, data, search]);

  const pageData = filteredData.slice(offset, offset + limit);

  const maxValue = useMemo(() => getMaxBreakdownValue(data), [data]);
  const maxDecimalPlaces = useMemo(() => getMaxDecimalPlaces(data), [data]);
  const groupings = useMemo(
    () =>
      filteredData.flatMap(({ grouping }) =>
        grouping?.map(({ column, value }) => ({
          [getGroupingKey(column, parent)]: value,
        })),
      ),
    [filteredData],
  );

  const columnsByKey = useMemo(() => {
    const map: Record<string, TableColumn> = {
      metricName: {
        headerSx: { pl: "0 !important" },
        header: () => (
          <Row align="center" gap={2}>
            <Text color="text.secondary">Metric </Text>
            <Box>
              <Badge>{getNumberOfUniqueValues(data, metricNameKey)}</Badge>
            </Box>
            {/** At minimum, the metric column is shown. Don't need to show below if it's the only column */}
            {columnKeys.length > 1 && (
              <Box>
                <Tooltip message="Move column to the left" openSpeed="slow">
                  <IconButton
                    aria-label="Move metric name column to the left."
                    icon={ChevronLeftIcon}
                    isDisabled={columnKeys[0] === metricNameKey}
                    size="sm"
                    onClick={() =>
                      setColumnKeys(move(columnKeys, metricNameKey, "left"))
                    }
                  />
                </Tooltip>
                <Tooltip message="Move column to the right" openSpeed="slow">
                  <IconButton
                    aria-label="Move metric name column to the right."
                    icon={ChevronRightIcon}
                    isDisabled={
                      columnKeys[columnKeys.length - 1] === metricNameKey
                    }
                    size="sm"
                    onClick={() =>
                      setColumnKeys(move(columnKeys, metricNameKey, "right"))
                    }
                  />
                </Tooltip>
              </Box>
            )}
          </Row>
        ),
        min: "240px",
        max: "240px",
        cellSx: {
          display: "contents",
        },
        cell: ({ metricName }, index) => {
          const isFirstOfGroup =
            pageData?.[index - 1]?.metricName !== metricName;
          const isLastOfGroup =
            pageData?.[index + 1]?.metricName !== metricName;
          const isLastColumn =
            columnKeys.indexOf("metricName") === columnKeys.length - 1;

          return (
            <Box
              sx={getTdStyles({ isFirstOfGroup, isLastColumn, isLastOfGroup })}
            >
              <TextWithTooltip
                color="text.secondary"
                message={metricName}
                size="sm"
              >
                {metricName}
              </TextWithTooltip>
            </Box>
          );
        },
      },
      splitName: {
        headerSx: { pl: "0 !important" },
        header: () => (
          <Row align="center" gap={2}>
            <Text color="text.secondary">Split</Text>{" "}
            <Box>
              <Badge>
                {getNumberOfUniqueValues(data, splitNameKey, [undefined])}
              </Badge>
            </Box>
            <Box>
              <Tooltip message="Move column to the left" openSpeed="slow">
                <IconButton
                  aria-label="Move split name column to the left."
                  icon={ChevronLeftIcon}
                  isDisabled={columnKeys[0] === splitNameKey}
                  size="sm"
                  onClick={() =>
                    setColumnKeys(move(columnKeys, splitNameKey, "left"))
                  }
                />
              </Tooltip>
              <Tooltip message="Move column to the right" openSpeed="slow">
                <IconButton
                  aria-label="Move split name column to the right."
                  icon={ChevronRightIcon}
                  isDisabled={
                    columnKeys[columnKeys.length - 1] === splitNameKey
                  }
                  size="sm"
                  onClick={() =>
                    setColumnKeys(move(columnKeys, splitNameKey, "right"))
                  }
                />
              </Tooltip>
            </Box>
          </Row>
        ),
        min: "168px",
        max: "168px",
        cellSx: {
          display: "contents",
        },
        cell: ({ splitName }: GraphSeries, index) => {
          const isFirstOfGroup = pageData?.[index - 1]?.splitName !== splitName;
          const isLastOfGroup = pageData?.[index + 1]?.splitName !== splitName;
          const isLastColumn =
            columnKeys.indexOf("splitName") === columnKeys.length - 1;

          return (
            <Box
              sx={getTdStyles({ isFirstOfGroup, isLastColumn, isLastOfGroup })}
            >
              <TextWithTooltip
                color="text.secondary"
                message={splitName ?? "--"}
                size="sm"
              >
                {splitName ?? "--"}
              </TextWithTooltip>
            </Box>
          );
        },
      },
      value: {
        header: valueColumnName
          ? () => (
              <Row align="center">
                <Text color="text.secondary">{valueColumnName}</Text>
              </Row>
            )
          : undefined,
        min: "160px",
        cell: ({
          color = "electric.500",
          audienceName,
          splitName,
          grouping,
          metricName,
          aggregation,
          data,
          normalization,
        }: GraphSeries) => {
          const metricValue = data?.[0]?.metricValue;

          if (maxValue === undefined || metricValue === undefined) {
            return <Text color="text.secondary">Not available</Text>;
          }

          const maxAllowedDecimalPlaces = metricValue < 1 ? 4 : 2;
          const value = shouldUsePercentFormat(aggregation, normalization)
            ? `${(metricValue * 100).toFixed(2)}%`
            : // XXX: temp solution to make sure the bar width are accurate by making
              // all the values have the same decimal places to avoid issues with different
              // label lengths
              format(
                metricValue,
                "0,0." +
                  (maxDecimalPlaces
                    ? "0".repeat(
                        Math.min(maxDecimalPlaces, maxAllowedDecimalPlaces),
                      )
                    : "X"), // X format will remove decimal point as necessary
              );

          const fillWidth = 100 * (metricValue / maxValue);
          const fullName = getSeriesDescription({
            audienceName:
              numberOfUniqueAudiences > 1 ? audienceName : undefined,
            splitName,
            groupByColumns: grouping,
          });

          return (
            <AnalyticsBar
              metricName={metricName}
              value={value}
              tooltipValue={value}
              tooltipText={fullName}
              color={color}
              width={fillWidth}
            />
          );
        },
      },
    };

    if (numberOfUniqueAudiences > 1) {
      map[audienceNameKey] = {
        headerSx: { pl: "0 !important" },
        disabled: () => true,
        header: () => (
          <Row align="center" gap={2}>
            <Text color="text.secondary">Audience</Text>
            <Box>
              <Badge>{numberOfUniqueAudiences}</Badge>
            </Box>
            <Box>
              <Tooltip message="Move column to the left" openSpeed="slow">
                <IconButton
                  aria-label="Move audience name column to the left."
                  icon={ChevronLeftIcon}
                  isDisabled={columnKeys[0] === audienceNameKey}
                  size="sm"
                  onClick={() =>
                    setColumnKeys(move(columnKeys, audienceNameKey, "left"))
                  }
                />
              </Tooltip>
              <Tooltip message="Move column to the right" openSpeed="slow">
                <IconButton
                  aria-label="Move audience name column to the right."
                  icon={ChevronRightIcon}
                  isDisabled={
                    columnKeys[columnKeys.length - 1] === audienceNameKey
                  }
                  size="sm"
                  onClick={() =>
                    setColumnKeys(move(columnKeys, audienceNameKey, "right"))
                  }
                />
              </Tooltip>
            </Box>
          </Row>
        ),
        min: "240px",
        max: "240px",
        cellSx: {
          display: "contents",
        },
        cell: ({ audienceName }, index) => {
          const isFirstOfGroup =
            pageData?.[index - 1]?.audienceName !== audienceName;
          const isLastOfGroup =
            pageData?.[index + 1]?.audienceName !== audienceName;
          const isLastColumn =
            columnKeys.indexOf("audienceName") === columnKeys.length - 1;

          return (
            <Box
              sx={getTdStyles({ isFirstOfGroup, isLastColumn, isLastOfGroup })}
            >
              <TextWithTooltip
                color="text.primary"
                message={audienceName}
                size="sm"
              >
                {audienceName}
              </TextWithTooltip>
            </Box>
          );
        },
      };
    }

    (data?.[0]?.grouping || groupByColumns).forEach(
      (column: GroupByColumn | GroupColumn, groupingIndex) => {
        const groupByColumn = (
          "column" in column ? column.column : column
        ) as GroupByColumn;

        const columnName = getPropertyNameFromColumn(groupByColumn);
        const modelId = getModelIdFromColumn(groupByColumn);
        const groupingPath = `grouping.${groupingIndex}.value`;

        if (columnName) {
          map[groupingPath] = {
            headerSx: { pl: "0 !important" },
            header: () => {
              return (
                <Row align="center" gap={2}>
                  <Text color="text.secondary">
                    {("alias" in column && column.alias) || columnName}
                  </Text>
                  <Box>
                    <Badge>
                      {getNumberOfUniqueValues(
                        groupings,
                        getGroupingKey(groupByColumn, parent),
                        [undefined],
                      )}
                    </Badge>
                  </Box>
                  {columnKeys.length > 1 && (
                    <Box>
                      <Tooltip
                        message="Move column to the left"
                        openSpeed="slow"
                      >
                        <IconButton
                          aria-label="Move audience name column to the left."
                          icon={ChevronLeftIcon}
                          isDisabled={columnKeys[0] === groupingPath}
                          size="sm"
                          onClick={() =>
                            setColumnKeys(
                              move(columnKeys, groupingPath, "left"),
                            )
                          }
                        />
                      </Tooltip>
                      <Tooltip
                        message="Move column to the right"
                        openSpeed="slow"
                      >
                        <IconButton
                          aria-label="Move audience name column to the right."
                          icon={ChevronRightIcon}
                          isDisabled={
                            columnKeys[columnKeys.length - 1] === groupingPath
                          }
                          size="sm"
                          onClick={() =>
                            setColumnKeys(
                              move(columnKeys, groupingPath, "right"),
                            )
                          }
                        />
                      </Tooltip>
                    </Box>
                  )}
                </Row>
              );
            },
            min: "240px",
            cellSx: {
              display: "contents",
            },
            cell: ({ grouping }: GraphSeries, index) => {
              const value = grouping?.find(({ column: groupingColumn }) => {
                const groupingColumnName =
                  getPropertyNameFromColumn(groupingColumn);
                const groupingColumnModelId =
                  getModelIdFromColumn(groupingColumn);

                // We want to group event columns of the same name but we don't
                // want to group it with the parent model columns so make sure
                // to check the groupByColumn modelId when the groupBy is related
                // the parent model
                if (isGroupByColumnRelatedToParent(parent, groupByColumn)) {
                  return (
                    groupingColumnName === columnName &&
                    groupingColumnModelId === modelId
                  );
                }

                return (
                  groupingColumnName === columnName &&
                  groupingColumnModelId !== parent?.id?.toString()
                );
              });

              if (!value) {
                return (
                  <Text color="text.secondary" size="sm">
                    --
                  </Text>
                );
              }

              const previousRowValue = pageData?.[index - 1]?.grouping?.find(
                ({ column: groupingColumn }) =>
                  getPropertyNameFromColumn(groupingColumn) === columnName,
              );
              const nextRowValue = pageData?.[index + 1]?.grouping?.find(
                ({ column: groupingColumn }) =>
                  getPropertyNameFromColumn(groupingColumn) === columnName,
              );
              const isFirstOfGroup = previousRowValue?.value !== value.value;
              const isLastOfGroup = nextRowValue?.value !== value.value;
              const isLastColumn =
                columnKeys.indexOf(groupingPath) === columnKeys.length - 1;

              return (
                <Box
                  sx={getTdStyles({
                    isFirstOfGroup,
                    isLastColumn,
                    isLastOfGroup,
                  })}
                >
                  <TextWithTooltip
                    color="text.primary"
                    size="sm"
                    message={value.value ?? "--"}
                  >
                    {value.value ?? "--"}
                  </TextWithTooltip>
                </Box>
              );
            },
          };
        }
      },
    );

    return map;
  }, [data, groupByColumns, groupings, page]);

  const columns = (columnKeys as string[])
    .concat(["value"])
    .map((key) => columnsByKey[key])
    .filter(isPresent);

  return (
    <Column flex={1} minHeight={0} gap={4}>
      {enableSearch && data.length > 0 && (
        <SearchInput
          placeholder="Search series..."
          value={search}
          onChange={(event) => setSearch(event.target.value)}
        />
      )}

      <Column height="100%" overflow="auto" flex={1} gap={2}>
        <Table
          defaultMin="min-content"
          columns={columns}
          data={pageData}
          loading={isLoading}
          rowHeight="40px"
          placeholder={{
            image: searchPlaceholder,
            title: "No breakdowns found",
            error:
              "Breakdowns failed to load. Please check your configuration and try again.",
          }}
        />

        <Box py={4}>
          <Pagination
            page={page}
            setPage={setPage}
            rowsPerPage={limit}
            count={filteredData.length}
          />
        </Box>
      </Column>
    </Column>
  );
};
