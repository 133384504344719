import { FC, useState } from "react";

import { Button, Dialog, Paragraph, useToast } from "@hightouchio/ui";
import { Link } from "src/router";
import { useCreateEventVolumeReportMutation } from "src/graphql";
import { withPermission } from "src/components/permission";

const EventReportButton: FC = () => {
  const { toast } = useToast();

  const createReportMutation = useCreateEventVolumeReportMutation();
  const [signedUrl, setSignedUrl] = useState<string>("");
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Button
        isLoading={createReportMutation.isLoading}
        variant="secondary"
        onClick={async () => {
          const r = await createReportMutation.mutateAsync({});
          if (
            !r.createEventVolumeReport?.ok ||
            !r.createEventVolumeReport?.url
          ) {
            return toast({
              id: "run-graph",
              title: "There was a problem generating your report.",
              message: "Please contact support or try again later.",
              variant: "error",
            });
          }
          const signedUrl = r.createEventVolumeReport.url;
          setSignedUrl(signedUrl);
          setIsOpen(true);
        }}
      >
        Generate report
      </Button>
      <Dialog
        isOpen={isOpen}
        title="Event volume report"
        variant="info"
        actions={
          <Button variant="primary" onClick={() => setIsOpen(false)}>
            Close
          </Button>
        }
        onClose={() => setIsOpen(false)}
      >
        <Paragraph>
          <Link href={signedUrl}>Download CSV</Link>
        </Paragraph>
      </Dialog>
    </>
  );
};

export const PermissionedEventReportButton = withPermission(EventReportButton);
