import { FC, useEffect, useState } from "react";

import {
  Alert,
  Box,
  Button,
  ButtonGroup,
  CloseIcon,
  DrawerBody,
  DrawerHeader,
  IconButton,
  InformationIcon,
  Paragraph,
  Row,
  SectionHeading,
  Stats,
  StatsItem,
  StatsItemTitle,
  StatsItemValue,
  Text,
  Tooltip,
  useToast,
  WarningIcon,
} from "@hightouchio/ui";
import { Link } from "src/router";
import { useFlags } from "launchdarkly-react-client-sdk";
import { groupBy } from "lodash";
import pluralize from "pluralize";
import { useQueryClient } from "react-query";

import { PlanWelcomeModal } from "src/components/modals/plan-welcome-modal";
import { PricingModal } from "src/components/modals/pricing-modal";
import {
  PricingPlan,
  pricingPlans,
} from "src/components/pricing/pricing-display";
import { useUser } from "src/contexts/user-context";
import {
  useBillingOrganizationQuery,
  useDestinationDefinitionsQuery,
  useEventSourcesQuery,
  useOrganizationMetricsQuery,
  usePortalUrlQuery,
} from "src/graphql";
import { useEntitlements } from "src/hooks/use-entitlement";
import * as analytics from "src/lib/analytics";
import { commaNumber } from "src/utils/numbers";
import { useQueryString } from "src/utils/use-query-string";

import { DestinationTiles } from "./components/destination-tiles";
import { FeatureCatalog } from "./components/feature-catalog";
import { OrganizationActiveSyncsTable } from "./components/organization-active-syncs-table";
import { OrganizationMqrTable } from "./components/organization-mqr-table";
import {
  WorkspaceMetrics,
  WorkspaceMetricsTable,
} from "./components/workspace-metrics-table";
import { Drawer } from "src/components/drawer";
import { PermissionedEventReportButton } from "./components/event-report-button";

export const Billing: FC = () => {
  const { workspace } = useUser();
  const client = useQueryClient();
  const { toast } = useToast();
  const { data: entitlementsData } = useEntitlements(true, true);
  const { data: organizationMetrics } = useOrganizationMetricsQuery(undefined, {
    suspense: true,
  });
  const { data: destinationDefinitions } = useDestinationDefinitionsQuery(
    undefined,
    { suspense: true },
  );
  const { data: organizationData } = useBillingOrganizationQuery(undefined, {
    suspense: true,
  });
  const { data: eventsData } = useEventSourcesQuery(undefined, {
    suspense: true,
  });
  const [showWorkspaces, setShowWorkspaces] = useState(false);
  const [showDestinations, setShowDestinations] = useState(false);
  const [loadingStripe, setLoadingStripe] = useState(false);
  const {
    data: { success, canceled },
  } = useQueryString();
  const [successfulCheckoutPlan, setSuccessfulCheckoutPlan] = useState<
    PricingPlan | undefined
  >();
  const [isPricingModalOpen, setIsPricingModalOpen] = useState(false);
  useEffect(() => {
    if (success) {
      const plan = pricingPlans.find(
        (pricingPlans) => pricingPlans.sku === success,
      );
      if (plan) {
        analytics.track("Checkout Successful", {
          workspace_id: workspace?.id,
          sku: plan.sku,
        });
        setSuccessfulCheckoutPlan(plan);
      }
    }
  }, [success]);

  const { mqrTable: mqrTableEnabled, syncsUsageTable: syncsUsageTableEnabled } =
    useFlags();

  useEffect(() => {
    if (canceled) {
      analytics.track("Checkout canceled", {
        workspace_id: workspace?.id,
      });
    }
  }, [canceled]);

  useEffect(() => {
    analytics.track("Billing Screen Viewed", {
      workspace_id: workspace?.id,
    });
  }, []);

  const organization = organizationData?.getBillingOrganization;
  const entitlements = entitlementsData?.entitlements;
  const overage = entitlementsData?.overage;
  const destinationsCounts = entitlementsData?.destinationsCounts;
  const eventSources = eventsData?.event_sources;

  const rolesByWorkspaceId = groupBy(
    organizationMetrics?.getOrganizationMetrics?.roles,
    "workspace_id",
  );

  const usageMetrics: WorkspaceMetrics[] =
    organizationMetrics?.getOrganizationMetrics?.metrics?.map((metric) => ({
      ...metric,
      roles: rolesByWorkspaceId[metric.id],
    })) || [];

  const openPricingModal = (source: string) => () => {
    setIsPricingModalOpen(true);
    analytics.track("Upgrade Plan Clicked", {
      workspace_id: workspace?.id,
      source,
    });
  };

  const goToPortal = async () => {
    if (!workspace) {
      return;
    }

    if (organization?.billing?.customer_id) {
      setLoadingStripe(true);
      analytics.track("Manage Plan Clicked", {
        workspace_id: workspace.id,
      });
      try {
        const { getPortalUrl } = await client.fetchQuery(
          usePortalUrlQuery.getKey({
            customer_id: workspace.organization?.billing?.customer_id,
          }),
          usePortalUrlQuery.fetcher({
            customer_id: workspace.organization?.billing?.customer_id,
          }),
        );
        if (getPortalUrl.redirect) {
          location.href = getPortalUrl.redirect;
        } else {
          toast({
            id: "go-to-portal",
            title: "Couldn't generate portal URL",
            variant: "error",
          });

          setLoadingStripe(false);
        }
      } catch (e) {
        toast({
          id: "go-to-portal",
          title: "Couldn't generate portal URL",
          message: e.message,
          variant: "error",
        });

        setLoadingStripe(false);
      }
    }
  };

  const hasOverages =
    overage.overage && (overage.destinationOverage || overage.premiumOverage);
  const planLimit = entitlements?.destinations || 1;

  return (
    <>
      {overage.overage && (
        <Box display="flex" flexDirection="column" gap={4}>
          {overage.destinationOverage && (
            <Alert
              message="As a temporary courtesy, your syncs are still running while this workspace is in overage. Please remove destinations or upgrade your plan."
              title="You've exceeded the number of destination types allowed on your current plan"
              type="warning"
              variant="inline"
              actions={
                <Button
                  variant="secondary"
                  onClick={openPricingModal("destinations_banner")}
                >
                  Upgrade plan
                </Button>
              }
            />
          )}

          {overage.premiumOverage && (
            <Alert
              message="You've used a premium destination type and need to upgrade your plan."
              title="Premium destination used"
              type="warning"
              variant="inline"
              actions={
                <Button
                  variant="secondary"
                  onClick={openPricingModal("premium_banner")}
                >
                  Upgrade plan
                </Button>
              }
            />
          )}
        </Box>
      )}

      <Box mt={hasOverages ? 8 : 0}>
        <SectionHeading>
          Overview {organization ? ` for ${organization.name}` : ""}
        </SectionHeading>

        <Stats mt={6}>
          <StatsItem>
            <StatsItemTitle>Plan</StatsItemTitle>
            <StatsItemValue>
              {organization?.plan?.name || "Free"}
            </StatsItemValue>

            {organization?.plan?.sku !== "business_tier" &&
              (organization?.billing?.customer_id ? (
                <ButtonGroup mt={3}>
                  <Button
                    isLoading={loadingStripe}
                    size="sm"
                    onClick={() => {
                      goToPortal();
                    }}
                  >
                    Manage billing
                  </Button>

                  <Button size="sm" onClick={openPricingModal("existing_plan")}>
                    Upgrade plan
                  </Button>
                </ButtonGroup>
              ) : (
                <Button mt={3} size="sm" onClick={openPricingModal("kpi_bar")}>
                  Upgrade plan
                </Button>
              ))}
          </StatsItem>

          <StatsItem>
            <StatsItemTitle>Destination usage</StatsItemTitle>

            <StatsItemValue>
              {destinationsCounts.billableDestinations} of{" "}
              {Number(entitlements?.destinations) === -1 ? (
                <span>&infin;</span>
              ) : (
                planLimit
              )}
            </StatsItemValue>

            <Button mt={3} size="sm" onClick={() => setShowDestinations(true)}>
              View all destination types
            </Button>
          </StatsItem>

          <StatsItem>
            <StatsItemTitle>Workspaces</StatsItemTitle>
            <StatsItemValue>{usageMetrics.length}</StatsItemValue>

            <Button mt={3} size="sm" onClick={() => setShowWorkspaces(true)}>
              View all workspaces
            </Button>
          </StatsItem>

          <StatsItem>
            <StatsItemTitle>Cost</StatsItemTitle>

            <StatsItemValue>
              {organization?.plan?.sku === "business_tier"
                ? "Contract"
                : `$${
                    organization?.billing?.subscription_cost
                      ? `${commaNumber(
                          organization?.billing?.subscription_cost,
                        )} / ${
                          workspace!.organization?.billing
                            ?.subscription_interval
                        }`
                      : "0"
                  }`}
            </StatsItemValue>
          </StatsItem>
        </Stats>
      </Box>

      <Drawer
        isOpen={showWorkspaces}
        size="xl"
        onClose={() => setShowWorkspaces(false)}
      >
        <DrawerHeader>
          <Row align="center" justify="space-between" w="100%">
            Workspaces
            <IconButton
              aria-label="Close modal"
              icon={CloseIcon}
              onClick={() => setShowWorkspaces(false)}
            />
          </Row>
        </DrawerHeader>

        <DrawerBody>
          <WorkspaceMetricsTable metrics={usageMetrics} />
        </DrawerBody>
      </Drawer>

      <Drawer
        isOpen={showDestinations}
        size="xl"
        onClose={() => setShowDestinations(false)}
      >
        <DrawerHeader>
          <Row align="center" justify="space-between" w="100%">
            Destination types
            <IconButton
              aria-label="Close modal"
              icon={CloseIcon}
              onClick={() => setShowDestinations(false)}
            />
          </Row>
        </DrawerHeader>
        <DrawerBody>
          <Box display="flex" flexDirection="column" gap={6} pb={6}>
            <Box
              border="1px"
              borderColor="gray.300"
              borderRadius="md"
              display="flex"
              gap={14}
              p={4}
            >
              <Box alignItems="center" display="flex" gap={1}>
                <Text fontWeight="semibold">Plan limit:</Text>

                <Text>
                  {Number(entitlements?.destinations) === -1 ? (
                    <span>&infin;</span>
                  ) : (
                    planLimit
                  )}{" "}
                  {pluralize("destination type", planLimit)}
                </Text>
              </Box>

              <Box alignItems="center" display="flex" gap={1}>
                <Text fontWeight="semibold">Current billable usage:</Text>

                <Box alignItems="center" display="inline-flex" gap={2}>
                  <Text>
                    {pluralize(
                      "destination type",
                      destinationsCounts.billableDestinations,
                      true,
                    )}
                  </Text>

                  {overage.overage && (
                    <Tooltip
                      message={`You are using billable ${pluralize(
                        "destination type",
                        destinationsCounts.billableDestinations,
                        true,
                      )}${
                        destinationsCounts.premiumDestinations > 0
                          ? `, including premium ${pluralize(
                              "destination type",
                              destinationsCounts.premiumDestinations,
                              true,
                            )},`
                          : ""
                      } of the ${planLimit} included in your plan.`}
                    >
                      <WarningIcon color="warning.base" />
                    </Tooltip>
                  )}
                </Box>
              </Box>
            </Box>

            <Box border="1px" borderColor="gray.300" borderRadius="md" p={4}>
              <Stats>
                <StatsItem>
                  <StatsItemTitle>Billable Destination Types</StatsItemTitle>
                  <StatsItemValue>
                    {destinationsCounts.billableDestinations}
                  </StatsItemValue>
                </StatsItem>

                <StatsItem>
                  <StatsItemTitle>Free Destination Types</StatsItemTitle>
                  <StatsItemValue>
                    {destinationsCounts.freeDestinations}
                  </StatsItemValue>
                </StatsItem>

                <StatsItem>
                  <StatsItemTitle>Total Count</StatsItemTitle>
                  <StatsItemValue>
                    {destinationsCounts.totalDestinations}
                  </StatsItemValue>
                </StatsItem>
              </Stats>
            </Box>

            <DestinationTiles
              definitions={destinationDefinitions}
              destinationBillingTypes={
                entitlementsData?.destinationBillingTypes
              }
            />
          </Box>
        </DrawerBody>
      </Drawer>

      {syncsUsageTableEnabled && organization && (
        <Box mt={10}>
          <Box mb={8}>
            <Row align="center" gap={2}>
              <SectionHeading>Active syncs</SectionHeading>
              <Tooltip message="Active syncs are the number of unique syncs that run at least once in a calendar month, irrespective of frequency or data volume. Deleted syncs count towards this metric.">
                <Box fontSize="20px">
                  <InformationIcon color="text.secondary" />
                </Box>
              </Tooltip>
            </Row>

            <Paragraph>
              This is the number of unique syncs that ran at least once in each
              calendar month. The count includes all workspaces in your
              organization.
            </Paragraph>
          </Box>
          <OrganizationActiveSyncsTable />
        </Box>
      )}

      {mqrTableEnabled && organization && (
        <Box mt={20}>
          <Box mb={8}>
            <Row align="center" gap={2}>
              <SectionHeading>Monthly queried rows</SectionHeading>
              <Tooltip message="Monthly queried rows (or MQRs) are the number of deduped records across all models used by an active sync in the time period.">
                <Box fontSize="20px">
                  <InformationIcon color="text.secondary" />
                </Box>
              </Tooltip>
            </Row>

            <Paragraph>
              Learn more in our{" "}
              <Link
                href={`${import.meta.env.VITE_WEBSITE_URL}/docs/pricing/mqrs/`}
              >
                docs
              </Link>
              .
            </Paragraph>
          </Box>

          <OrganizationMqrTable
            organizationWorkspaces={organization.workspaces}
          />
        </Box>
      )}

      {eventSources?.length && (
        <Box mt={20}>
          <Box mb={8}>
            <Row align="center" gap={2}>
              <SectionHeading>Event collection</SectionHeading>
              <Tooltip message="Volume of events per source per month">
                <Box fontSize="20px">
                  <InformationIcon color="text.secondary" />
                </Box>
              </Tooltip>
            </Row>

            <PermissionedEventReportButton
              permission={{
                v2: {
                  grant: "can_update",
                  resource: "workspace",
                },
              }}
            />
          </Box>
        </Box>
      )}

      <Box mt={20}>
        <SectionHeading>Plan features</SectionHeading>

        <Box mt={6}>
          <FeatureCatalog />
        </Box>
      </Box>

      {successfulCheckoutPlan && (
        <PlanWelcomeModal
          checkList={successfulCheckoutPlan.checkList}
          isOpen={Boolean(successfulCheckoutPlan)}
          plan={successfulCheckoutPlan.plan}
          onClose={() => {
            setSuccessfulCheckoutPlan(undefined);
          }}
        />
      )}

      {isPricingModalOpen && (
        <PricingModal
          isOpen={isPricingModalOpen}
          organization={organization}
          onClose={() => {
            analytics.track("Pricing Modal Dismissed", {
              workspace_id: workspace?.id,
            });
            setIsPricingModalOpen(false);
          }}
        />
      )}
    </>
  );
};
